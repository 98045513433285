<style src="./style.css"></style>
<style src="./bootstrap.min.css"></style>
<template>
  <div class="container infinity-container">
    <div class="row">
      <div class="col-md-1 infinity-left-space"></div>
      <!-- FORM BEGIN -->
      <div class="col-lg-14 col-md-14 col-sm-16 col-xs-18 text-center infinity-form">
        <!-- Company Logo -->
        <div class="text-center mb-3 mt-5">
          <h1>
            <img src="/logo.jpg" style="width: 60px; height: 60px;">
          </h1>
        </div>
        <div class="text-center mb-4">
          <h4>心动游-客服系统</h4>
        </div>
        <!-- Form -->
        <form class="px-3">
          <!-- Input Box -->
          <div class="form-input">
            <span><img src="./user.svg" style="height: 16px;width: 16px"/></span>
            <input type="text" name="" placeholder="请输入用户名" tabindex="10" required>
          </div>
          <div class="form-input">
            <span><img src="./lock.svg" style="height: 16px;width: 16px"/></span>
            <input type="password" name="" placeholder="请输入密码" required>
          </div>
          <div class="row mb-3">
            <!-- Remember Box -->
            <div class="col-auto d-flex align-items-center">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="cb1">
                <label class="custom-control-label" for="cb1">记住密码</label>
              </div>
            </div>
          </div>
          <!-- Login Button -->
          <div class="mb-3">
            <button type="submit" @click.prevent="handleLogin" class="btn btn-block">登录</button>
          </div>
          <!--					<div class="text-right ">-->
          <!--		                <a href="reset.html" class="forget-link">忘记密码?</a>-->
          <!--		            </div>-->
          <!--					<div class="text-center mb-2">-->
          <!--	                   	<div class="text-center mb-3" style="color: #777;">其它登录方式</div>-->
          <!--		                   	-->
          <!--	                   	&lt;!&ndash; Facebook Button &ndash;&gt;-->
          <!--	                   	<a href="" class="btn btn-social btn-qq"><i class="fa fa-qq"></i></a>-->

          <!--                    	&lt;!&ndash; Google Button &ndash;&gt;-->
          <!--						<a href="" class="btn btn-social btn-weixin"><i class="fa fa-weixin"></i></a>-->

          <!--						&lt;!&ndash; Twitter Button &ndash;&gt;-->
          <!--						<a href="" class="btn btn-social btn-weibo"><i class="fa fa-weibo"></i></a>-->
          <!--					</div>-->
          <!--					<div class="text-center mb-5" style="color: #777;">没有用户名? -->
          <!--						<a class="register-link" href="">立即注册</a>-->
          <!--			       	</div>-->
        </form>
      </div>
      <!-- FORM END -->
      <div class="col-md-1 infinity-right-space"></div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {}
  },
  mounted() {
  },
  methods: {
    handleLogin() {
      this.$message.error('暂未开放外部登录')
    }
  },

}
</script>